'use client' // Ensures this code runs on the client side in Next.js 13+
import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { usePathname } from 'next/navigation' // Uses the new Next.js navigation API
import { AnchorHTMLAttributes, useMemo } from 'react' // Import useMemo for efficient computation
import RoughNotationWrapper from './RoughNotationWrapper'

function hrefToAriaLabel(href: string) {
  // Remove the protocol (http, https), www, and domain extensions like .com
  const urlWithoutProtocol = href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
  const domainExtensionRemoved = urlWithoutProtocol.replace(/\.[a-z]{2,}(\/|$)/, ' ')

  // Split the URL into parts based on slashes
  const parts = domainExtensionRemoved.split('/')

  // Filter out empty parts and common file extensions
  const meaningfulParts = parts.filter(
    (part) => part && !part.match(/\.(jpg|jpeg|png|gif|html|php)$/)
  )

  // Replace hyphens and underscores with spaces for readability
  const readableParts = meaningfulParts.map((part) => part.replace(/[-_]+/g, ' '))

  // Join the parts with a space and capitalize the first letter of each part
  const label = readableParts.join(' - ').replace(/\b\w/g, (char) => char.toUpperCase())

  return label.trim() || 'Web Link' // Return 'Web Link' if the result is empty
}

type CustomLinkProps = LinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    removeRoughNotationWrapper?: boolean
    normalText?: boolean
  }

const CustomLink = ({
  href,
  className,
  removeRoughNotationWrapper,
  normalText,
  ...rest
}: CustomLinkProps) => {
  const isInternalLink = href && href.startsWith('/')
  const isAnchorLink = href && href.startsWith('#')
  const pathname = usePathname()

  // Use useMemo to conditionally add the yellow text class if pathname matches href
  const finalClassName = useMemo(() => {
    if (pathname === href) {
      return `${className || ''} ${!normalText && 'text-secondary hover:text-secondary-500 dark:text-primary dark:hover:text-primary-200'}  `
    }
    return className || ''
  }, [pathname, href, className])

  if (isInternalLink) {
    return (
      <>
        {pathname === href && !removeRoughNotationWrapper ? (
          <RoughNotationWrapper type="underline" show animationDuration={1500} iterations={3}>
            <Link href={href} {...rest} className={finalClassName} />
          </RoughNotationWrapper>
        ) : (
          <Link href={href} {...rest} className={finalClassName} />
        )}
      </>
    )
  }

  if (isAnchorLink) {
    return <a href={href} className={finalClassName} {...rest} aria-label={hrefToAriaLabel(href)} />
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      aria-label={hrefToAriaLabel(href)}
      className={`${finalClassName} no-underline`}
      {...rest}
    />
  )
}

export default CustomLink
