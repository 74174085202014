'use client'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import { useEffect, useState } from 'react'

const HomeWorldImage = () => {
  const [mounted, setMounted] = useState(false)
  const { theme, resolvedTheme } = useTheme()

  // useEffect only runs on the client, so now we can safely show the UII
  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <>
      {mounted ? (
        <Image
          className="h-full w-full rounded-3xl object-cover object-left"
          src={
            theme === 'dark' || resolvedTheme === 'dark'
              ? '/static/images/home/long-card-4-image.png'
              : '/static/images/home/long-card-4-image-orange.png'
          }
          alt={'Mappamondo'}
          sizes="auto"
          height={300}
          width={300}
          priority
        />
      ) : null}
    </>
  )
}

export default HomeWorldImage
