'use client'
import { useTheme } from 'next-themes'
import React, { useEffect, useState } from 'react'
import { RoughNotation, RoughNotationProps } from 'react-rough-notation'

interface RoughNotationWrapperProps extends RoughNotationProps {
  children: React.ReactNode
  // You might want to remove the 'color' prop from here if you're planning to exclusively control it based on the theme
}

const RoughNotationWrapper: React.FC<RoughNotationWrapperProps> = ({ children, ...props }) => {
  const [mounted, setMounted] = useState(false)
  const { theme, resolvedTheme } = useTheme()

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true)
  }, [])

  // Determine color based on the theme or resolvedTheme
  const color = theme === 'dark' || resolvedTheme === 'dark' ? '#12c290' : '#f66323'

  return (
    <>
      {mounted ? (
        <RoughNotation
          color={color} // Apply the theme-based color
          {...props} // Spread the rest of the props
        >
          {children}
        </RoughNotation>
      ) : (
        children
      )}
    </>
  )
}

export default RoughNotationWrapper
