'use client'

import { motion } from 'framer-motion'
import { useTheme } from 'next-themes'
import { ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
}

export function HomeTitleLine({ children }: Props) {
  const [mounted, setMounted] = useState(false)
  const { theme, resolvedTheme } = useTheme()

  useEffect(() => {
    setMounted(true)
  }, [])

  const color = theme === 'dark' || resolvedTheme === 'dark' ? '#21eab0' : '#f66323'

  return (
    <>
      {children}
      <motion.div className="title-line-container">
        <svg
          width="100%" // Make width responsive
          height="16" // Fixed height; adjust if necessary
          viewBox="0 0 534 16" // Adjust viewBox to the original width and height
          preserveAspectRatio="xMidYMid meet" // Adjust this value to fit your design
          fill="none"
        >
          {mounted ? (
            <motion.path
              d="M407.012 7.85962L305.152 8L204.027 7.75L102.902 7.5H2.88145C2.58423 7.5 2.30875 7.34424 2.15552 7.08956V7.08956C1.84377 6.57138 2.1597 5.90282 2.75798 5.81468L4.89412 5.5L10.9999 5L74.4025 3L198.402 2L495.902 3H507.297C507.367 3 507.437 3.00499 507.507 3.01493V3.01493C509.093 3.24158 509.246 5.47328 507.705 5.91357L507.605 5.942C507.471 5.98048 507.331 6 507.191 6H495.902L376.454 8.49891C376.419 8.49964 376.431 8.49942 376.396 8.50011C375.274 8.52243 207.394 11.8593 100.014 14"
              stroke={color}
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              initial={{
                strokeDasharray: 2000,
                strokeDashoffset: 2000,
              }}
              animate={{
                strokeDashoffset: 0,
              }}
              transition={{
                duration: 1.5,
              }}
            />
          ) : null}
        </svg>
      </motion.div>
    </>
  )
}
