'use client'

import { useTheme } from 'next-themes'
import { useEffect, useRef, useState } from 'react'

const TrustBox = () => {
  const ref = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const { theme, resolvedTheme } = useTheme()

  useEffect(() => {
    if ((window as any).Trustpilot) {
      ;(window as any).Trustpilot.loadFromElement(ref.current, true)
    }
    setIsLoaded(true)
  }, [])

  return (
    <div
      ref={ref}
      className={`trustpilot-widget w-[350px] bg-customWhiteBackgroundColor dark:bg-customDarkBackgroundColor ${isLoaded ? 'visible' : 'hidden'}`}
      data-locale="it-IT"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="6654551e5781ee1d454efa2c"
      data-style-height="52px"
      data-style-width="100%"
      data-theme={resolvedTheme || theme}
      data-group="on"
      data-stars="1,2,3,4,5"
    >
      <a href="https://it.trustpilot.com/review/meamenu.com" target="_blank" rel="noopener">
        Trustpilot
      </a>
    </div>
  )
}

export default TrustBox
