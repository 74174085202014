'use client'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import { useEffect, useState } from 'react'

const HomeQrCodeImage = () => {
  const [mounted, setMounted] = useState(false)
  const { theme, resolvedTheme } = useTheme()

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <>
      {mounted ? (
        <Image
          className="h-full w-full object-cover"
          src={
            theme === 'dark' || resolvedTheme === 'dark'
              ? '/static/images/home/QR_code.svg'
              : '/static/images/home/QR_code_orange.svg'
          }
          alt={'QR code'}
          width={400}
          height={400}
          sizes="auto"
          priority
        />
      ) : null}
    </>
  )
}

export default HomeQrCodeImage
