'use client'

import ClockClockWise from './../../../public/static/images/home/icons/clock-clockwise.svg'
import Lightbulb from './../../../public/static/images/home/icons/lightbulb.svg'
import PencilSimpleLine from './../../../public/static/images/home/icons/pencil-simple-line.svg'
import Truck from './../../../public/static/images/home/icons/truck.svg'
import UserPlus from './../../../public/static/images/home/icons/user-plus.svg'

const IconSwitcher = ({ iconKey }) => {
  const icons = {
    one: Truck,
    two: UserPlus,
    three: PencilSimpleLine,
    four: UserPlus,
    five: Lightbulb,
    six: ClockClockWise,
  }

  const SvgIcon = icons[iconKey]

  return <SvgIcon className="h-12 w-12 object-cover text-secondary dark:text-primary" />
}

const HomeTipsCard = ({ iconSrc, label, title, description }) => (
  <div className="container flex flex-col rounded-xl p-4  dark:bg-customBackgroundCardColor">
    <div className="relative mb-4 flex items-center rounded-xl bg-clip-border">
      <div>
        <IconSwitcher iconKey={iconSrc} />
      </div>
      {label && (
        <span className="border-blue btn-primary  mx-2  rounded-full bg-secondary-500 px-2  py-2 text-xs font-semibold text-white no-underline shadow-md focus:outline-none active:shadow-none dark:bg-primary-800 dark:text-primary-400">
          {label}
        </span>
      )}
    </div>

    <div className="mt-2 flex flex-col lg:mt-0 lg:h-40">
      <div className="flex-1 ">
        <p className="block text-2xl font-semibold text-gray-800 dark:text-white ">{title}</p>

        <p className=" multiline-truncate prose mt-3 max-w-none text-lg  text-gray-800 dark:text-gray-400">
          {description}
        </p>
      </div>
    </div>
  </div>
)

export default HomeTipsCard
